import { Defaults } from '../components/common';
import { t } from '../config/i18n';

export const EMAIL_REGEX = new RegExp(/^[\w.]{2,}@[\w.]{2,}\.\w{2,}$/);

export function formatNumber(
  f?: number,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number,
): string {
  return f !== undefined
    ? Intl.NumberFormat(undefined, {
        maximumFractionDigits,
        minimumFractionDigits,
      }).format(f)
    : '';
}

export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: number = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  options?: { wait?: number; immediate?: boolean },
) {
  const { wait, immediate } = { ...{ wait: 20, immediate: true }, ...options };
  let previous = 0;
  let timer: number | null = null;
  return function (...args) {
    if (immediate) {
      const now = Date.now();

      if (now - previous > wait) {
        func.apply(this, args);
        previous = now;
      }
    } else {
      if (!timer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        timer = setTimeout(() => {
          timer = null;
          func.apply(this, args);
        }, wait);
      }
    }
  } as T;
}

export const removeSpaces = (str?: string) => (str ? str.replaceAll(/\s/gi, '') : str);
export const removeCyrillic = (str?: string) =>
  str ? str.replaceAll(/[а-яА-ЯёЁ]/gi, '') : str;

export const isEmail = (email?: string): boolean => {
  if (!email) return false;
  return EMAIL_REGEX.test(email);
};

export function getCryptoFracDigits(crypto?: string): number {
  return crypto === 'BTC' ? 8 : 8;
}

export function formatCrypto(f?: number, crypto?: string): string {
  return formatNumber(f, getCryptoFracDigits(crypto));
}

export function formatDate(d: Date): string {
  return d.toLocaleDateString();
}

export function formatDateTime(d: Date): string {
  return d.toLocaleString(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
  });
}

export function getUTCTime(d?: Date): number | undefined {
  return d ? d.getTime() / 1000 - d.getTimezoneOffset() * 60 : undefined;
}

export function formatString(s?: string, ...args: any): string {
  console.log(s);
  if (!s) {
    return '';
  }
  for (let i = 0; i < args.length; i++) {
    if (s.includes(`{${i}}`)) s = s.split(`{${i}}`).join(args[i]);
  }
  return highlight(s);
}

export function highlight(s?: string): string | undefined {
  if (!s) {
    return;
  }
  return s.replace('<hl>', '<font color="#0097db">').replace('</hl>', '</font>');
}

export function escapeHtml(s?: string): string | undefined {
  if (!s) {
    return;
  }
  return s.replace(/<.+?>/g, '');
}

export function formatFileSize(bytes?: number): [number, 'b' | 'kb' | 'mb' | 'gb'] {
  if (bytes === undefined) {
    return [0, 'b'];
  }
  const kb = Math.round(bytes / 1024);
  if (kb === 0) {
    return [bytes, 'b'];
  }
  const mb = Math.round(kb / 1024);
  if (mb === 0) {
    return [kb, 'kb'];
  }
  const gb = Math.round(mb / 1024);
  if (gb === 0) {
    return [mb, 'mb'];
  }
  return [gb, 'gb'];
}

export function roundFloat(f: number, n?: number): number {
  return parseFloat(f.toFixed(n || 0));
}

export function roundCrypto(f: number, crypto?: string): number {
  return truncNumber(f, getCryptoFracDigits(crypto));
}

export function parseNumber(s?: string | null): number | undefined {
  return s ? Number(s) : undefined;
}

export function truncNumber(f: number, fracDigits: number): number {
  const ss = String(f).split('.');
  if (ss.length < 2) {
    return f;
  }
  ss[1] = ss[1].substr(0, fracDigits);
  return parseFloat(ss.join('.'));
}

export function secondsToText(
  seconds: number,
): [number, 'second' | 'minute' | 'hour' | 'day' | 'week'] {
  seconds = Math.floor(seconds);
  const w = Math.floor(seconds / 60 / 60 / 24 / 7);
  if (w > 0) {
    return [w, 'week'];
  }

  const d = Math.floor(seconds / 60 / 60 / 24);
  if (d > 0) {
    return [d, 'day'];
  }

  const h = Math.floor(seconds / 60 / 60);
  if (h > 0) {
    return [h, 'hour'];
  }

  const m = Math.floor(seconds / 60);
  if (m > 0) {
    return [m, 'minute'];
  }

  return [seconds, 'second'];
}

export function parseDate(s?: string): Date | undefined {
  if (!s || s === 'None') {
    return;
  }

  const ss = s.split(' ');
  if (ss.length < 2) {
    return;
  }

  const d = ss[0].split('-');
  const t = ss[1].split('.');
  if (t.length < 1) {
    return;
  }

  const tt = t[0].split(':');
  if (tt.length < 3) {
    return;
  }

  return new Date(
    Date.UTC(
      parseInt(d[0], 10),
      parseInt(d[1], 10) - 1,
      parseInt(d[2], 10),
      parseInt(tt[0], 10),
      parseInt(tt[1], 10),
      parseInt(tt[2], 10),
    ),
  );
}

export function getNumberParts(f: number): { int: number; float: number } {
  const int = Math.trunc(f);
  const float = f - int;
  return { int, float };
}

export function copyToClipboard(value?: string) {
  function isIOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  if (!value) {
    return Promise.reject('no value provided');
  }

  if (!navigator.clipboard) {
    const textField = document.createElement('textarea');
    textField.innerText = value;
    textField.style.position = 'absolute';
    textField.style.top = '0';
    textField.inputMode = 'none';
    textField.readOnly = true;
    document.body.appendChild(textField);

    if (isIOS()) {
      const range = document.createRange();
      range.selectNodeContents(textField);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textField.setSelectionRange(0, 999999);
    } else {
      textField.select();
    }
    document.execCommand('copy');
    textField.remove();
    return Promise.resolve();
  }

  notify(t('notifications.copied'), 'success');
  return navigator.clipboard.writeText(value);
}

export function findObjectInList<T>(list: T[], key: keyof T, value: any): T | undefined {
  const filtered = list.filter((i) => i[key] === value);
  return filtered.length > 0 ? filtered[0] : undefined;
}

export function sortData<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    return order !== 0 ? order : a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export type SortDirection = 'asc' | 'desc';

export function getSorter<K extends keyof any>(
  direction: SortDirection,
  sortBy: K,
): (
  a: { [key in K]: number | string | Date },
  b: { [key in K]: number | string | Date },
) => number {
  return direction === 'desc'
    ? (a, b) => desc(a, b, sortBy)
    : (a, b) => -desc(a, b, sortBy);
}

function desc<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  return b[orderBy] > a[orderBy] ? 1 : 0;
}

export function toUpperCase(s?: string): string | undefined {
  return s !== undefined ? s.toUpperCase() : undefined;
}

export function toLowerCase(s?: string): string | undefined {
  return s !== undefined ? s.toLowerCase() : undefined;
}

export function nvl(v: any, ...def: any): any {
  if (v === null) {
    v = undefined;
  }
  if (v !== undefined) {
    return v;
  }
  for (const d of def) {
    if (d !== undefined) {
      return d;
    }
  }
}

export function length(value: any): number {
  return value ? value.toString().length : 0;
}

export function openSkyBot() {
  openLink('http://sky-crypto.ru/sky');
}

export function openLink(path: string) {
  path = path.match(/^https?:/) ? path : 'https://' + path;
  window.open(path, '_blank');
}

export function urlToOpen(path: string): string {
  return path.match(/^https?:/) ? path : 'https://' + path;
}

export function isEmpty(obj: Record<any, any>): boolean {
  return Object.keys(obj).length === 0;
}

export function onEnterPress(f: () => void): (e) => void {
  return (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      f();
      return false;
    }
    return true;
  };
}

export const MobileWidthLimit = 1280;

export function onResize(f: () => void) {
  f();
  window.addEventListener('resize', f);
}

export function offResize(f: () => void) {
  window.removeEventListener('resize', f);
}

export function getSkyPayFakeEmail(): string {
  const arr = new Uint8Array(15);
  window.crypto.getRandomValues(arr);
  const salt = Array.from(arr, (n) => (n < 10 ? '0' + String(n) : n.toString(16))).join(
    '',
  );
  return `${salt}@noemail.fkfl`;
}

export function classNames(...args): string {
  return args.filter((className) => className).join(' ');
}

export const BreakPoints = {
  mobile: 480,
  tablet: 768,
  laptop: 1024,
  desktop: 1200,
};

function getNotifyColor(type?: string) {
  switch (type) {
    case 'error':
      return `${Defaults.redColor}ab`;
    case 'info':
      return `${Defaults.mainColor}ab`;
    case 'success':
      return `${Defaults.greenColor}ab`;
    case 'neutral':
      return `${Defaults.textColor}ab`;
    default:
      return `${Defaults.textColor}ab`;
  }
}

export function notify(
  string: string,
  type?: 'error' | 'info' | 'success' | 'neutral',
  duration?: number,
) {
  const notification = document.getElementById('notification');
  const notificationsContainer = document.getElementById('notifications-container');
  const textNode = document.createTextNode(string);
  if (notification && notificationsContainer) {
    const notificationClone = notification.cloneNode(true) as HTMLElement;
    notificationClone.appendChild(textNode);
    notificationClone.removeAttribute('id');
    notificationsContainer.appendChild(notificationClone);
    notificationClone.style.setProperty('opacity', '1');
    notificationClone.style.setProperty('position', 'relative');
    notificationClone.style.setProperty('background-color', getNotifyColor(type));
    window.setTimeout(() => {
      notificationClone?.style.setProperty('opacity', '0');
      window.setTimeout(() => notificationClone.remove(), 300);
    }, duration ?? 2000);
  }
}
