import React, { useMemo } from 'react';

import { SBP_BANK } from '../../helpers/settings';
import { urlToOpen } from '../../helpers/utils';
import { Purchase, Sale } from '../../utils/types';
import { Column, Defaults, Row } from '../common';
import { DealAction } from '../common/panels/controls';
import { BankType } from '../common/types';
import ActionButton from '../controls/ActionButton';
import { PageText } from '../controls/Page';

export const RestartPanel = ({
  pm,
  onRestart,
  onRestartAlt,
  onCancel,
  deal,
  type,
}: {
  pm: BankType;
  onRestart;
  onRestartAlt?: () => void;
  deal: Purchase | Sale;
  onCancel: () => void;
  type: 'sale' | 'purchase';
}) => {
  const isRubPurchase = useMemo(() => {
    return (
      type === 'purchase' && deal.currency.toUpperCase() === 'RUB' && pm?.id !== SBP_BANK
    );
  }, [deal.currency, type, pm?.id]);

  const actions = useMemo(() => {
    if (isRubPurchase) {
      return (
        <>
          <ActionButton
            buttonColor={Defaults.greenColor}
            hoverColor={Defaults.greenColor}
            caption={'common.yes'}
            width={'100px'}
            onClick={onRestartAlt}
          />
          <ActionButton
            buttonColor={Defaults.greenColor}
            hoverColor={Defaults.greenColor}
            caption={'common.no'}
            width={'100px'}
            onClick={onRestart}
          />
        </>
      );
    }
    return (
      <ActionButton
        buttonColor={Defaults.greenColor}
        hoverColor={Defaults.greenColor}
        caption={'common.choose-bank'}
        width={'fit-content'}
        onClick={onRestart}
      />
    );
  }, [deal.currency, type]);

  return (
    <Column alignItems={'center'}>
      <PageText
        k={`${type}.restart${isRubPurchase ? '-rub' : ''}`}
        size={'3vh'}
        top={'1vh'}
        textAlign={'center'}
        args={[pm?.name ? `"${pm.name}"` : '']}
      />
      <Row top={'3vh'} gap={'50px'}>
        {actions}
      </Row>
      {type === 'purchase' && (
        <Row top={'3vh'} gap={'50px'}>
          <DealAction
            caption={'purchase.redirect'}
            swapColors
            onClick={() => {
              window.open(
                urlToOpen(deal.back_url || deal.merchant.website || ''),
                deal?.status === 1 ? '_blank' : '_self',
              );
            }}
            width={'250px'}
          />
        </Row>
      )}
    </Column>
  );
};
